// Preloader.js
import React from 'react';
import { BarLoader } from 'react-spinners';

import styles from './Preloader.module.css';

const Preloader = ({ loading }) => {
  return (
    <div className={`${styles.preloader} ${loading ? styles.visible : styles.hidden}`}>
      <BarLoader color="#ffffff" loading={loading} width={240} />
    </div>
  );
};

export default Preloader;
