import LostCrossItem from './LostCrossItem';

function LostCrossList({ list, ...props }) {
    return (
        <div {...props}>
            {list.map((verse_block) => (
                <LostCrossItem key={verse_block.verse_title} data={verse_block} />
            ))}
        </div>
    );
}
    
export default LostCrossList;