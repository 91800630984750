import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { fetchCategories, fetchBooks, getCategory } from './features/settingsSlice';

import Main from '../pages/Main';
import Preloader from '../components/layout/Preloader/Preloader';

import './App.css';

function App() {
  const dispatch = useDispatch();

  const viewMode = useSelector((state) => state.settings.viewMode);
  const activeVerse = useSelector((state) => state.settings.activeVerse);
  const activeCategory = useSelector((state) => state.settings.activeCategory);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchBooks());

    if (viewMode === 'category' && activeVerse.categories.length > 0) {

      if (!activeCategory) {
        dispatch(getCategory(activeVerse.categories[0]._id));
      }

    }

  }, [viewMode, activeCategory, activeVerse, dispatch]);

  useEffect(() => {

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);

  }, []);

  return (
    <Router>
        <Preloader loading={loading} />
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/:slug" element={<Main />} />
        </Routes>
    </Router>
  );
}

export default App;
