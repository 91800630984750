import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getRandomVerse, searchVerse } from '../../services/api/verseService';
import { setViewMode, setActiveCategory, getCategory, setIsUserSearching } from '../../app/features/settingsSlice';

import Menu from './Menu/Menu';
import ScrollableWrapper from './ScrollableWrapper';
import LinkWrapper from '../ui/wrappers/LinkWrapper';
import ButtonDefault from '../ui/buttons/ButtonDefault/ButtonDefault';

import styles from './Header.module.css';

import shuffle from '../../assets/shuffle-icon.svg';
import shuffleBlack from '../../assets/shuffle-icon_black.svg';

function Header({ className, ...props}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchInputRef = useRef(null);

    const viewMode = useSelector((state) => state.settings.viewMode);
    const isMobile = useSelector((state) => state.settings.isMobile);
    const activeVerse = useSelector((state) => state.settings.activeVerse);
    const isUserSearching = useSelector((state) => state.settings.isUserSearching);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    // eslint-disable-next-line
    const handleRandomVerse = async () => {
        try {
            const response = await getRandomVerse();

            if (response.slug) {

                if (response?.categories.length > 0 && response?.categories[0]?._id) {
                    dispatch(getCategory(response.categories[0]._id));
                }

                navigate(`/${response.slug}`);
            }
            // console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    // eslint-disable-next-line
    const onViewmodeChange = (mode) => {

        if (mode !== viewMode) {
            dispatch(setViewMode(mode));

            if (mode === 'category') {
                let category = '';

                if (activeVerse?.categories?.length > 0) {
                    category = activeVerse.categories[0]._id;
                }

                dispatch(getCategory(category));
            }
            else {
                dispatch(setActiveCategory(null));
            }

        }

    }

    const debounce = (fn, delay) => {
        let timeoutId;
        return (...args) => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => fn.apply(this, args), delay);
        };
    }
    
    const handleMobileSearchClick = () => {

        if (window.innerWidth <= 1024) {
            setIsSearchOpen(!isSearchOpen);
        }

    }

    // Debounce the search function
    // eslint-disable-next-line
    const debounceSearch = useCallback(debounce(async (query) => {

        if (query.length > 0) {

            searchVerse({ search: query }).then((data) => {
                setResults(data);
            }).catch((error) => {
                console.log(error);
            });

        } else {
            setResults([]);
        }

        setIsSearching(false);

    }, 500), []);

    useEffect(() => {
        if (query.length > 0) {
            debounceSearch(query);
            setIsSearching(true);
        } else {
            setResults([]);
            setIsSearching(false);
        }
    }, [query, debounceSearch]);

    useEffect(() => {

        const handleKeydown = (event) => {
            const key = event.keyCode;
            const isModifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;

            // Ignore common shortcuts like Cmd+C, Cmd+V, etc.
            if (isModifierKey) {
                return;
            }
            
            if (key === 67) {
                event.preventDefault();
                
                if (viewMode === 'canon') {
                    onViewmodeChange('category');
                }
                else {
                    onViewmodeChange('canon');
                }

            }

            if (key === 83) {
                event.preventDefault();
                searchInputRef.current.focus();
                setIsSearchOpen(true);
                dispatch(setIsUserSearching(true));
            }
            
            if (key === 86) {
                event.preventDefault();
                handleRandomVerse();
            }

        };

        if (!isUserSearching) {
            window.addEventListener('keydown', handleKeydown);
        }
    
        return () => {
          window.removeEventListener('keydown', handleKeydown);
        };
    }, [isUserSearching, viewMode, handleRandomVerse, onViewmodeChange, dispatch]);

    return (
        <>
            {isSearchOpen && <div className={styles.overlay}></div>}
            <header className={`${styles.header} ${styles[className]} ${isSearchOpen ? styles.searchActive : ''} ${isMenuOpen ? styles.active : ''} ${isMobile ? styles.mobile : ''}`}>
                <div className="so-content">
                    <div className="so-row">
                        <div className={styles.col}>
                            <div className={styles.trigger} onClick={handleMenuClick}>
                                <i></i>
                                <i></i>
                                <i></i>
                            </div>

                            <div className={styles.mode}>
                                <span 
                                    className={`${viewMode === 'canon' ? styles.active : ''}`}
                                    onClick={() => onViewmodeChange('canon')}
                                >
                                    Canon
                                </span>
                                <span 
                                    className={`${viewMode === 'category' ? styles.active : ''}`}
                                    onClick={() => onViewmodeChange('category')}
                                >
                                    Category
                                </span>
                            </div>

                            <div className={styles.mode_mobile}>

                                {viewMode === 'canon' ? (
                                    <span onClick={() => onViewmodeChange('category')}>
                                        Canon
                                    </span>
                                ) : (
                                    <span onClick={() => onViewmodeChange('canon')}>
                                        Category
                                    </span>
                                )}

                            </div>
                        </div>
                        <div className={styles.col}>
                            
                            <div className={styles.search}>
                                <input 
                                    type="text" 
                                    placeholder="Search by word or verse..." 
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    onFocus={() => {
                                        setIsSearchOpen(true);
                                        dispatch(setIsUserSearching(true));
                                    }}
                                    onBlur={() => {
                                        if (window.innerWidth > 1024) {
                                            setTimeout(() => {
                                                setIsSearchOpen(false);
                                                dispatch(setIsUserSearching(false));
                                            }, 300)
                                        }
                                    }}
                                    ref={searchInputRef}
                                />
                                <button onClick={handleMobileSearchClick}></button>

                                {(!isSearching && isSearchOpen && query !== '' && results.length > 0) && (
                                    <ScrollableWrapper className={styles.search_list}>
                                        {results.map((element) => (
                                            <LinkWrapper 
                                                key={element.id} 
                                                to={`/${element.slug}`}
                                                onClick={() => {
                                                    setIsSearchOpen(false);

                                                    if (element.categories.length > 0) {
                                                        dispatch(getCategory(element.categories[0]));
                                                    }

                                                }}
                                            >
                                                {element.title}
                                            </LinkWrapper>
                                        ))}
                                    </ScrollableWrapper>
                                )}
                                {(!isSearching && isSearchOpen && query !== '' && results.length === 0) && (
                                    <ScrollableWrapper className={styles.search_list}>
                                        <p>No results found</p>
                                    </ScrollableWrapper>
                                )}
                            </div>

                            <ButtonDefault 
                                icon={shuffle}
                                iconHover={shuffleBlack}
                                style={{marginBottom: 30}}
                                onClick={handleRandomVerse}
                            >
                                Random  Verse
                            </ButtonDefault>

                        </div>
                    </div>
                </div>
            </header>
            <Menu isOpen={isMenuOpen} handleClose={handleMenuClick} />
        </>
    );
}

export default Header;