import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Settings from './Settings/Settings';
import Version from './Version/Version';

import { getVersesByChapterID, getVersesByCategoryID } from '../../../services/api/verseService';
import { getCategory, setIsCrossPopupOpen, setViewMode, setHighlight, setPresentMode, setMusic, setRepeat, setNotes, setCrossPopupSize } from '../../../app/features/settingsSlice';

import ScrollableWrapper from '../../layout/ScrollableWrapper';
import CanonList from '../../common/CanonList/CanonList';
import CategoryList from '../../common/CategoryList/CategoryList';
import LostCrossList from '../../common/LostCrossList/LostCrossList';
import LinkWrapper from '../../ui/wrappers/LinkWrapper';
import Popup from '../../common/Popup/Popup';

import styles from './Controlls.module.css';

import keyboard from '../../../assets/keyboard-icon.svg';

function Controlls({ children, className, instantHide, ...props }) {
    const chapterMenuRef = useRef(null);
    const categoryMenuRef = useRef(null);
    const categoryVersesMenuRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isUserSearching = useSelector(state => state.settings.isUserSearching);
    const isMobile = useSelector(state => state.settings.isMobile);
    const highlight = useSelector(state => state.settings.settings.highlight);
    const crossPopupSize = useSelector((state) => state.settings.settings.crossPopupSize);
    const viewMode = useSelector((state) => state.settings.viewMode);
    const activeVerse = useSelector((state) => state.settings.activeVerse);
    const canon_list = useSelector((state) => state.settings.canon_list);
    const activeCategory = useSelector((state) => state.settings.activeCategory);
    const categories = useSelector((state) => state.settings.categories);
    const settings = useSelector((state) => state.settings);
    const isCrossPopupOpen = useSelector((state) => state.settings.isCrossPopupOpen);

    const [isKeyboardPopupOpen, setIsKeyboardPopupOpen] = useState(false);
    const [isChapterMenuOpen, setIsChapterMenuOpen] = useState(false);
    const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
    const [isCategoryVersesMenuOpen, setIsCategoryVersesMenuOpen] = useState(false);
    const [versesList, setVersesList] = useState([]);
    const [categoryVersesList, setCategoryVersesList] = useState([]);

    const handleClickOutside = (event) => {
        if (chapterMenuRef.current && !chapterMenuRef.current.contains(event.target)) {
            if (event.target.dataset.action !== 'chapter_trigger') {
                setIsChapterMenuOpen(false);
            }
        }
        if (categoryMenuRef.current && !categoryMenuRef.current.contains(event.target)) {
            if (event.target.dataset.action !== 'category_trigger') {
                setIsCategoryMenuOpen(false);
            }
        }
        if (categoryVersesMenuRef.current && !categoryVersesMenuRef.current.contains(event.target)) {
            if (event.target.dataset.action !== 'category_verses_trigger') {
                setIsCategoryVersesMenuOpen(false);
            }
        }
    };

    useEffect(() => {
        if (isChapterMenuOpen || isCategoryMenuOpen || isCategoryVersesMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isChapterMenuOpen, isCategoryMenuOpen, isCategoryVersesMenuOpen]);

    useEffect(() => {

        if (viewMode === 'canon') {

            if (activeVerse?.chapters?.length > 0) {
                
                getVersesByChapterID({ chapterID: activeVerse.chapters[0]._id }).then((data) => {
                    // console.log(data);
                    setVersesList(data);
                }).catch((error) => {
                    console.log(error);
                });

            }

        }
        else {
            //
        }

    }, [viewMode, activeVerse]);

    useEffect(() => {

        // if (activeCategory === null && viewMode === 'category' && activeVerse.categories.length > 0) {
        //     dispatch(getCategory(activeVerse.categories[0]._id));
        //     // console.log(activeCategory);
        // }

        if (viewMode === 'category' && activeCategory !== null) {

            getVersesByCategoryID({ categoryID: activeCategory._id }).then((data) => {
                
                if (data.length > 0) {
                    setCategoryVersesList(data);
                }

            }).catch((error) => {
                console.log(error);
            });

        }

    }, [viewMode, activeCategory, activeVerse, navigate, dispatch]);

    const handlePrevChapter = useCallback(() => {

        if (activeVerse.books.length > 0 && activeVerse.chapters.length > 0) {

            const book_id = activeVerse.books[0]._id;

            // find book in canon_list
            const book = canon_list.find((book) => book._id === book_id);

            if (activeVerse.books[0].chapters.length > 0) {
                let first_verse = null;
                let chapter_index = book.chapters.findIndex((chapter) => chapter._id === activeVerse.chapters[0]._id);

                // loop from chapter_index to 0
                for (let i = chapter_index - 1; i >= 0; i--) {
                    if (book.chapters[i].first_verse) {
                        first_verse = book.chapters[i].first_verse;
                        break;
                    }
                }

                if (first_verse) {
                    navigate(`/${first_verse}`);
                }
                else {
                    const prev_book = [...canon_list].reverse().find((book) => book.count > 0 && book.order < activeVerse.books[0].order);

                    if (prev_book) {
                        // loop from chapter_index to last
                        for (let i = prev_book.chapters.length - 1; i >= 0; i--) {
                            if (prev_book.chapters[i].first_verse) {
                                first_verse = prev_book.chapters[i].first_verse;
                                break;
                            }
                        }

                        if (first_verse) {
                            navigate(`/${first_verse}`);
                        }
                    }

                }

            }

        }

    }, [activeVerse.books, activeVerse.chapters, canon_list, navigate]);

    const handleNextChapter = useCallback(() => {

        if (activeVerse.books.length > 0 && activeVerse.chapters.length > 0) {
            const book_id = activeVerse.books[0]._id;
            // find book in canon_list
            const book = canon_list.find((book) => book._id === book_id);
            
            if (activeVerse.books[0].chapters.length > 0) {
                let first_verse = null;
                let chapter_index = book.chapters.findIndex((chapter) => chapter._id === activeVerse.chapters[0]._id);
                // loop from chapter_index to last
                for (let i = chapter_index + 1; i < book.chapters.length; i++) {
                    if (book.chapters[i].first_verse) {
                        first_verse = book.chapters[i].first_verse;
                        break;
                    }
                }

                if (first_verse) {
                    navigate(`/${first_verse}`);
                }
                else {
                    // find next book with count > 0 and order > activeVerse.books[0].order
                    const next_book = canon_list.find((book) => book.count > 0 && book.order > activeVerse.books[0].order);

                    if (next_book) {
                        let chapter_index = next_book.chapters.findIndex((chapter) => chapter._id === activeVerse.chapters[0]._id);
                        // loop from chapter_index to last
                        for (let i = chapter_index + 1; i < next_book.chapters.length; i++) {
                            if (next_book.chapters[i].first_verse) {
                                first_verse = next_book.chapters[i].first_verse;
                                break;
                            }
                        }

                        if (first_verse) {
                            navigate(`/${first_verse}`);
                        }
                    }

                }

            }
        }
    }, [activeVerse.books, activeVerse.chapters, canon_list, navigate]);

    const handlePrevVerse = useCallback((index) => {

        if (index > 1) {
            navigate(`/${categoryVersesList[index - 2].slug}`);
        }

    }, [categoryVersesList, navigate]);

    const handleNextVerse = useCallback((index) => {

        if (index <= categoryVersesList.length - 1) {
            navigate(`/${categoryVersesList[index].slug}`);
        }

    }, [categoryVersesList, navigate]);

    let currentVerseIndex = 0;

    if (categoryVersesList.length > 0) {
        currentVerseIndex = categoryVersesList.findIndex((verse) => verse.slug === activeVerse.slug) + 1;
    }

    useEffect(() => {

        const handleKeydown = (event) => {
            const key = event.keyCode;
            const isModifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;

            // Ignore common shortcuts like Cmd+C, Cmd+V, etc.
            if (isModifierKey) {
                return;
            }

            // L
            if (key === 76) {
                if (activeVerse.lost_cross_list.length > 0 && activeVerse.lost_cross_list[0] !== false) {
                    event.preventDefault();
                    dispatch(setIsCrossPopupOpen(!isCrossPopupOpen));
                }
            }

            // X
            if (key === 88) {
                event.preventDefault();

                if (viewMode === 'canon') {
                    handleNextChapter();
                }
                else {
                    // find activeCategory index in categories
                    const category_index = categories.findIndex((category) => category._id === activeCategory._id);

                    if (category_index < categories.length - 1) {
                        dispatch(getCategory(categories[category_index + 1]._id));
                    }

                }

            }

            // Z
            if (key === 90) {
                event.preventDefault();

                if (viewMode === 'canon') {
                    handlePrevChapter();
                }
                else {
                    // find activeCategory index in categories
                    const category_index = categories.findIndex((category) => category._id === activeCategory._id);

                    if (category_index > 0) {
                        dispatch(getCategory(categories[category_index - 1]._id));
                    }

                }

            }

            // ->
            if (key === 39) {
                event.preventDefault();

                if (viewMode === 'canon') {

                    const verse_index = versesList.findIndex((verse) => verse._id === activeVerse._id);

                    if (verse_index < versesList.length - 1) {
                        navigate(`/${versesList[verse_index + 1].slug}`);
                    }
                    else {
                        handleNextChapter();
                    }

                }
                else {
                    handleNextVerse(currentVerseIndex);
                }

            }

            // <-
            if (key === 37) {
                event.preventDefault();

                if (viewMode === 'canon') {

                    const verse_index = versesList.findIndex((verse) => verse._id === activeVerse._id);

                    if (verse_index > 0) {
                        navigate(`/${versesList[verse_index - 1].slug}`);
                    }
                    else {
                        handlePrevChapter();
                    }

                }
                else {
                    handlePrevVerse(currentVerseIndex);
                }

            }

        };

        if (!isUserSearching) {
            window.addEventListener('keydown', handleKeydown);
        }
    
        return () => {
          window.removeEventListener('keydown', handleKeydown);
        };
    }, [isUserSearching, viewMode, activeCategory, categories, versesList, activeVerse, currentVerseIndex, navigate, dispatch, handleNextChapter, handlePrevChapter, handlePrevVerse, handleNextVerse, isCrossPopupOpen]);

    return (
        <>
            <div className={`${styles.controlls} ${styles[className]} ${(isChapterMenuOpen || isCategoryMenuOpen || isCategoryVersesMenuOpen) ? styles.active_menu : ''} ${viewMode === 'category' ? styles.category_mode : ''}`} {...props}>
                <div className={styles.row}>

                    {isChapterMenuOpen && (
                        <div className={`${styles.menu} ${styles.menu_chapter}`} ref={chapterMenuRef}>
                            <ScrollableWrapper>
                                <h6>Select Chapter</h6>
                                <span onClick={() => setIsChapterMenuOpen(false)}></span>
                                <CanonList 
                                    onChange={() => setIsChapterMenuOpen(false)}
                                />
                            </ScrollableWrapper>
                        </div>
                    )}

                    {isCategoryMenuOpen && (
                        <div className={`${styles.menu} ${styles.menu_category}`} ref={categoryMenuRef}>
                            <ScrollableWrapper>
                                <h6>Select Category</h6>
                                <span onClick={() => setIsCategoryMenuOpen(false)}></span>
                                <CategoryList 
                                    onChange={() => setIsCategoryMenuOpen(false)}
                                />
                            </ScrollableWrapper>
                        </div>
                    )}

                    {isCategoryVersesMenuOpen && (
                        <div className={`${styles.menu} ${styles.menu_verses}`} ref={categoryVersesMenuRef}>
                            <ScrollableWrapper>
                                <h6>Select Verse</h6>
                                <span onClick={() => setIsCategoryVersesMenuOpen(false)}></span>
                                <ul>
                                    {activeCategory && categoryVersesList.map((verse) => (
                                        <li key={verse._id}>
                                            <LinkWrapper 
                                                className={`${verse.slug === activeVerse.slug && styles.active}`}
                                                to={`/${verse.slug}`}
                                                onClick={() => setIsCategoryVersesMenuOpen(false)}
                                            >
                                                {verse.title}
                                            </LinkWrapper>
                                        </li>
                                    ))}
                                </ul>
                            </ScrollableWrapper>
                        </div>
                    )}

                    <div className={styles.content}>
                        <div className={styles.col}>
                            {(viewMode === 'canon' && activeVerse.chapters.length > 0) && (
                                
                                <div className={styles.canon_books_list}>
                                    <span 
                                        className={styles.canon_books_list_arrow}
                                        onClick={handlePrevChapter}
                                    ></span>
                                    <span 
                                        className={`${styles.canon_books_list_name} ${isChapterMenuOpen && styles.active}`}
                                        onClick={() => setIsChapterMenuOpen(!isChapterMenuOpen)}
                                        data-action="chapter_trigger"
                                    >
                                        {activeVerse.chapters[0].title}
                                    </span>
                                    <span 
                                        className={styles.canon_books_list_arrow}
                                        onClick={handleNextChapter}
                                    ></span>
                                </div>

                            )}

                            {(viewMode === 'category' && activeCategory !== null) && (
                                <div className={styles.category_block}>
                                    <span 
                                        className={`${styles.canon_books_list_name} ${isCategoryMenuOpen && styles.active}`}
                                        onClick={() => {
                                            setIsCategoryMenuOpen(!isCategoryMenuOpen);
                                            setIsCategoryVersesMenuOpen(false);
                                        }}
                                        data-action="category_trigger"
                                    >
                                        {isMobile ? 'Category: ' + activeCategory.title : activeCategory.title}
                                    </span>

                                    <span 
                                        className={`${styles.canon_books_list_name} ${isCategoryVersesMenuOpen && styles.active}`}
                                        onClick={() => {
                                            setIsCategoryVersesMenuOpen(!isCategoryVersesMenuOpen)
                                            setIsCategoryMenuOpen(false);
                                        }}
                                        data-action="category_verses_trigger"
                                    >
                                        {activeVerse.title}
                                    </span>
                                </div>
                            )}
                        </div>
                        
                        <div className={styles.col}>
                            {(viewMode === 'canon' && versesList.length > 0) && (
                                
                                <div className={styles.canon_verses_list_wrapper}>
                                    <span>Verse</span>

                                    <div className={styles.canon_verses_list}>
                                        {versesList.map((verse) => (
                                            <LinkWrapper 
                                                key={verse.id} 
                                                className={`${styles.canon_verses_list_item} ${verse.verse_number === activeVerse.verse_number && styles.active}`}
                                                to={`/${verse.slug}`}
                                            >
                                                {verse.verse_number}
                                            </LinkWrapper>
                                        ))}
                                    </div>
                                </div>

                            )}

                            {(viewMode === 'category' && categoryVersesList.length > 0) && (
                                <div className={styles.category_navigation}>
                                    <span className={styles.category_navigation_arrow} onClick={() => handlePrevVerse(currentVerseIndex)}></span>
                                    <span>{currentVerseIndex} / {categoryVersesList.length}</span>
                                    <span className={styles.category_navigation_arrow} onClick={() => handleNextVerse(currentVerseIndex)}></span>
                                </div>
                            )}
                        </div>

                        <div className={styles.col}>
                            <Version />
                        </div>
                    </div>

                    {(activeVerse.lost_cross_list.length > 0 && activeVerse.lost_cross_list[0] !== false) && (
                        <div 
                            className={styles.lost_cross_label} 
                            onClick={() => dispatch(setIsCrossPopupOpen(true))}
                        >
                            <strong>L</strong>
                            <span>Lost Cross Ref. ({activeVerse.lost_cross_list.length})</span>
                        </div>
                    )}
                    
                    {highlight ? null : <div className={styles.label}>Press 'H' to Toggle Highlights</div>}
                    <img 
                        className={styles.keyboard_icon} 
                        src={keyboard} 
                        alt="" 
                        onClick={() => setIsKeyboardPopupOpen(true)}
                    />
                    <Settings instantHide={instantHide} />

                </div>
            </div>
        <Popup
            isOpen={isCrossPopupOpen}
            closePopup={() => dispatch(setIsCrossPopupOpen(false))}
            className={`${styles.cross_popup} ${`cross_size_${crossPopupSize}`}`}
        >
            <div className={styles.cross_popup_head}>
                <div>
                    <h4>{activeVerse.title}</h4>
                </div>
                <div>
                    <span>Lost Cross Ref. ({activeVerse.lost_cross_list.length})</span>
                    <span className={styles.cross_popup_head_close} onClick={() => dispatch(setIsCrossPopupOpen(false))}></span>
                </div>
            </div>
            <div className={styles.cross_popup_body}>
                <ScrollableWrapper className={styles.cross_popup_body_wrapper}>
                    <LostCrossList list={activeVerse.lost_cross_list} />
                    <p className={styles.cross_popup_body_notice}>- Please select a verse above -</p>
                </ScrollableWrapper>
            </div>
            <div className={styles.cross_popup_sizing}>
                <span 
                    className={crossPopupSize === 3 ? styles.disabled : ''}
                    onClick={() => crossPopupSize < 3 ? dispatch(setCrossPopupSize(crossPopupSize + 1)) : null}
                ></span>
                <div>
                    <input type="range" value={crossPopupSize} min="1" max="3" step="1" onChange={(e) => dispatch(setCrossPopupSize(parseInt(e.target.value, 10)))} style={{ '--value': crossPopupSize }} />
                    <i className={styles.active}></i>
                    <i className={crossPopupSize > 1 ? styles.active : ''}></i>
                    <i className={crossPopupSize > 2 ? styles.active : ''}></i>
                </div>
                <span
                    className={crossPopupSize === 1 ? styles.disabled : ''}
                    onClick={() => crossPopupSize > 1 ? dispatch(setCrossPopupSize(crossPopupSize - 1)) : null}
                ></span>
            </div>
        </Popup>
        <Popup
            isOpen={isKeyboardPopupOpen}
            closePopup={() => setIsKeyboardPopupOpen(false)}
            className={styles.keyboard_popup}
        >
            <span className={styles.keyboard_popup_close} onClick={() => setIsKeyboardPopupOpen(false)}></span>
            <h4>KEYBOARD SHORTCUTS</h4>
            <div className={styles.keyboard_popup_line}></div>

            <div className={styles.keyboard_popup_data}>
                <div className={styles.keyboard_popup_col}>
                    <h6>TOP CONTROLS</h6>
                    <ul>
                        <li><strong onClick={() => dispatch(setViewMode(settings.viewMode === 'category' ? 'canon' : 'category'))}>C</strong> Mode [{settings.viewMode === 'category' ? 'Category' : 'Canon'}]</li>
                        <li><strong>S</strong> Search</li>
                        <li><strong>V</strong> Random Verse</li>
                    </ul>

                    <h6>BOTTOM CONTROLS</h6>
                    <ul>
                        <li><strong>X</strong> Next Chapter*</li>
                        <li><strong>Z</strong> Previous Chapter*</li>
                        <li><strong>→</strong> Next Verse</li>
                        <li><strong>←</strong> Previous Verse</li>
                        <li><strong>↓</strong> Next Translation</li>
                        <li><strong>↑</strong> Previous Translation</li>
                    </ul>
                    <p>* [Category Mode] X/Z = Next/Prev Category</p>
                </div>

                <div className={styles.keyboard_popup_col}>
                    <h6>GENERAL</h6>
                    <ul>
                        <li><strong>H</strong> Toggle Highlights</li>
                        <li><strong onClick={() => dispatch(setHighlight(!settings.settings.highlight))}>A</strong> Auto-Highlight {settings.settings.highlight ? <span>[On]</span> : <span style={{color: '#6E81A7'}}>[Off]</span>}</li>
                        <li><strong onClick={() => dispatch(setNotes(!settings.settings.notes))}>N</strong> Notes {settings.settings.notes ? <span>[On]</span> : <span style={{color: '#6E81A7'}}>[Off]</span>}</li>
                        <li><strong onClick={() => dispatch(setPresentMode(!settings.settings.present_mode))}>P</strong> Present Mode {settings.settings.present_mode ? <span>[On]</span> : <span style={{color: '#6E81A7'}}>[Off]</span>}</li>
                    </ul>

                    <h6>AUDIO</h6>
                    <ul>
                        <li><strong onClick={() => dispatch(setMusic(!settings.settings.music))}>M</strong> Music {settings.settings.music ? <span>[On]</span> : <span style={{color: '#6E81A7'}}>[Off]</span>}</li>
                        <li><strong>{`>`}</strong> Next Track</li>
                        <li><strong>{`<`}</strong> Previous Track</li>
                        <li><strong onClick={() => dispatch(setRepeat(!settings.settings.repeat))}>R</strong> Repeat {settings.settings.repeat ? <span>[On]</span> : <span style={{color: '#6E81A7'}}>[Off]</span>}</li>
                        <li><strong style={{marginRight: 5}}>1</strong> - <strong style={{marginLeft: 5}}>9</strong> Volume Level</li>
                        <li><strong>0</strong> Mute</li>
                    </ul>
                </div>
            </div>
        </Popup>
        </>
    );
}
    
export default Controlls;