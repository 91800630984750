import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './CanonListItem.module.css';

import { setActiveCategory, setViewMode } from '../../../app/features/settingsSlice';

function CanonListItem({ onChange, ...props }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const viewMode = useSelector((state) => state.settings.viewMode);
    const activeVerse = useSelector((state) => state.settings.activeVerse);

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    const handleChapterClick = (slug) => {

        if (viewMode !== 'canon') {
            dispatch(setActiveCategory(null));
            dispatch(setViewMode('canon'));
        }

        navigate(`/${slug}`);

        onChange();
    }

    let isActive = false;

    if (activeVerse.books.length > 0) {

        if (props.slug === activeVerse.books[0].slug) {
            isActive = true;
        }

    }

    // sort by props.chapters.order
    let sortedChapters = [];

    if (props?.chapters && isOpen) {
        sortedChapters = [...props.chapters].sort((a, b) => a.order - b.order);
    }

    return (
        <div key={props.id} className={`${styles.canon} ${props?.count ? '' : styles.disabled} ${isActive ? styles.active : ''}`}>
            <h3 onClick={handleOpen}>
                <span>{isOpen ? '-' : '+'}</span> {props.title}
            </h3>

            {(props?.chapters && isOpen) && (
                <ul>
                    {Array.from({ length: sortedChapters.length }, (_, i) => {
                        
                        return sortedChapters[i].count > 0 ? (
                            <li key={sortedChapters[i].id} onClick={() => handleChapterClick(sortedChapters[i].first_verse)}>{i + 1}</li>
                        ) : (
                            <li key={`disabled-${i + 1}`} className={styles.disabled}>{i + 1}</li>
                        );
                    })}
                </ul>
            )}

        </div>
    );
}
    
export default CanonListItem;