import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getVersesByCategoryID } from '../../../services/api/verseService';
import { setViewMode, getCategory } from '../../../app/features/settingsSlice';

import styles from './CategoryList.module.css';

function CategoryList({ onChange, ...props }) {
    const categories = useSelector((state) => state.settings.categories);
    const activeCategory = useSelector((state) => state.settings.activeCategory);
    const activeVerse = useSelector((state) => state.settings.activeVerse);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCategoryChange = async (category) => {
        await dispatch(getCategory(category));
        dispatch(setViewMode('category'));
        onChange();

        getVersesByCategoryID({ categoryID: category }).then((data) => {

            if (data.length > 0) {

                if (activeVerse.categories.length > 0) {
                    const cat = activeVerse.categories.find((cat) => cat._id === category);
                    console.log(activeVerse.title);

                    if (cat) {
                        // return;
                    }
                    else {
                        navigate(`/${data[0].slug}`);
                    }

                }

            }

        });

    };

    return (
        <ul className={styles.list} {...props}>
            {categories.map((category) => (
                <li 
                    key={category.id} 
                    onClick={() => handleCategoryChange(category._id)}
                    className={(activeCategory !== null && activeCategory.id === category.id) ? styles.active : ''}
                >
                    <span>{category.title}</span>
                    <span>{category.count}</span>
                </li>
            ))}
        </ul>
    );
}
    
export default CategoryList;