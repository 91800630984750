import styles from './ScrollableWrapper.module.css';

function ScrollableWrapper({ children, className, ...props }) {

    return (
        <div className={`${styles.wrapper} ${className}`} {...props}>
            {children}
        </div>
    );
}
    
export default ScrollableWrapper;