import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { Helmet } from 'react-helmet';

import { setActiveVerse, setIsCrossPopupOpen, setViewMode, getCategory } from '../../../app/features/settingsSlice';
import { getVerse } from '../../../services/api/verseService';

import LinkWrapper from '../../ui/wrappers/LinkWrapper';
import Version from '../Controlls/Version/Version';

import styles from './Verse.module.css';

function Verse() {
    const { slug } = useParams();
    const dispatch = useDispatch();

    const present_mode = useSelector(state => state.settings.settings.present_mode);
    const isUserSearching = useSelector(state => state.settings.isUserSearching);
    const notes = useSelector(state => state.settings.settings.notes);
    const highlight = useSelector(state => state.settings.settings.highlight);
    const verse = useSelector(state => state.settings.activeVerse);
    const activeVerseVersion = useSelector(state => state.settings.activeVerseVersion);

    const initFontSizeOrigin = 32 / 100 * verse.verse_size_1; // 32 * 100 / 32
    const initFontSizeAlternative = 30 / 100 * verse.verse_size_2; // 30 * 100 / 30

    const [fontSizeOrigin, setFontSizeOrigin] = useState(initFontSizeOrigin);
    const [fontSizeAlternative, setFontSizeAlternative] = useState(initFontSizeAlternative);
    const [optiotalHighlight, setOptionalHighlight] = useState(undefined);

    let initFade = false;
    if (present_mode) {
        initFade = 'fade-in';
    }
    const [fade, setFade] = useState(initFade);

    useEffect(() => {
        setFade('fade-out');

        if (slug) {

            let timeout = 0;

            if (present_mode) {
                timeout = 500;
            }

            setTimeout(() => {
                getVerse({ slug: slug }).then(response => {
                    dispatch(setActiveVerse(response));
                    setOptionalHighlight(undefined);
                    setFade('fade-in');
                });
            }, timeout);

        }
    
    // eslint-disable-next-line
    }, [slug, dispatch]);

    const resizeHandler = useCallback(() => {
        let newFontSizeOrigin = 32 / 100 * verse.verse_size_1;
        let newFontSizeAlternative = 30 / 100 * verse.verse_size_2;

        if (window.innerWidth >= 1600) {
            //
        }
        else if (window.innerWidth >= 1024) {
            newFontSizeOrigin = 26 / 100 * verse.verse_size_1;
            newFontSizeAlternative = 24 / 100 * verse.verse_size_2;
        }
        else if (window.innerWidth >= 768) {
            //
        }
        else if (window.innerWidth >= 600) {
            //
        }
        else {
            newFontSizeOrigin = 18 / 100 * verse.verse_size_1;
            newFontSizeAlternative = 17 / 100 * verse.verse_size_2;
        }

        setFontSizeOrigin(newFontSizeOrigin);
        setFontSizeAlternative(newFontSizeAlternative);
    }, [verse]);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [resizeHandler]);

    const handleCategoryChange = async (id) => {
        await dispatch(getCategory(id));
        dispatch(setViewMode('category'));
    }

    useEffect(() => {

        const handleKeydown = (event) => {
            const key = event.keyCode;
            const isModifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;

            // Ignore common shortcuts like Cmd+C, Cmd+V, etc.
            if (isModifierKey) {
                return;
            }
            
            if (key === 72) {
                event.preventDefault();

                if (optiotalHighlight === undefined) {
                    setOptionalHighlight(!highlight);
                }
                else {
                    setOptionalHighlight(!optiotalHighlight);
                }

            }

        };

        if (!isUserSearching) {
            window.addEventListener('keydown', handleKeydown);
        }
    
        return () => {
          window.removeEventListener('keydown', handleKeydown);
        };
    }, [isUserSearching, dispatch, optiotalHighlight, highlight]);

    let verseCompareTitle = '';
    let verseCompareContent = '';
    let verseCompareDescription = '';

    if (verse.description_kjb) {
        verseCompareDescription = verse.description_kjb;
    }

    if (activeVerseVersion === 'niv') {
        verseCompareTitle = 'NIV';
        verseCompareContent = verse.verse_niv;

        if (verse.description_niv) {
            verseCompareDescription = verse.description_niv;
        }
    }
    else if (activeVerseVersion === 'esv') {
        verseCompareTitle = 'ESV';
        verseCompareContent = verse.verse_esv;

        if (verse.description_esv) {
            verseCompareDescription = verse.description_esv;
        }
    }
    else if (activeVerseVersion === 'nasb') {
        verseCompareTitle = 'NASB';
        verseCompareContent = verse.verse_nasb;

        if (verse.description_nasb) {
            verseCompareDescription = verse.description_nasb;
        }
    }
    else if (activeVerseVersion === 'nkjv') {
        verseCompareTitle = 'NKJV';
        verseCompareContent = verse.verse_nkjv;

        if (verse.description_nkjv) {
            verseCompareDescription = verse.description_nkjv;
        }
    }
    else if (activeVerseVersion === 'lsb') {
        verseCompareTitle = 'LSB';
        verseCompareContent = verse.verse_lsb;

        if (verse.description_lsb) {
            verseCompareDescription = verse.description_lsb;
        }
    }
    else if (activeVerseVersion === 'nlt') {
        verseCompareTitle = 'NLT';
        verseCompareContent = verse.verse_nlt;

        if (verse.description_nlt) {
            verseCompareDescription = verse.description_nlt;
        }
    }
    else if (activeVerseVersion === 'csb') {
        verseCompareTitle = 'CSB';
        verseCompareContent = verse.verse_csb;

        if (verse.description_csb) {
            verseCompareDescription = verse.description_csb;
        }
    }
    else if (activeVerseVersion === 'amp') {
        verseCompareTitle = 'AMP';
        verseCompareContent = verse.verse_amp;

        if (verse.description_amp) {
            verseCompareDescription = verse.description_amp;
        }
    }
    else if (activeVerseVersion === 'hcsb') {
        verseCompareTitle = 'HCSB';
        verseCompareContent = verse.verse_hcsb;

        if (verse.description_hcsb) {
            verseCompareDescription = verse.description_hcsb;
        }
    }
    else if (activeVerseVersion === 'gnt') {
        verseCompareTitle = 'GNT';
        verseCompareContent = verse.verse_gnt;

        if (verse.description_gnt) {
            verseCompareDescription = verse.description_gnt;
        }
    }
    else if (activeVerseVersion === 'ceb') {
        verseCompareTitle = 'CEB';
        verseCompareContent = verse.verse_ceb;

        if (verse.description_ceb) {
            verseCompareDescription = verse.description_ceb;
        }
    }
    else if (activeVerseVersion === 'rsv') {
        verseCompareTitle = 'RSV';
        verseCompareContent = verse.verse_rsv;

        if (verse.description_rsv) {
            verseCompareDescription = verse.description_rsv;
        }
    }
    else if (activeVerseVersion === 'nrsv') {
        verseCompareTitle = 'NRSV';
        verseCompareContent = verse.verse_nrsv;

        if (verse.description_nrsv) {
            verseCompareDescription = verse.description_nrsv;
        }
    }
    else if (activeVerseVersion === 'net') {
        verseCompareTitle = 'NET';
        verseCompareContent = verse.verse_net;

        if (verse.description_net) {
            verseCompareDescription = verse.description_net;
        }
    }
    else if (activeVerseVersion === 'msg') {
        verseCompareTitle = 'MSG';
        verseCompareContent = verse.verse_msg;

        if (verse.description_msg) {
            verseCompareDescription = verse.description_msg;
        }
    }
    else if (activeVerseVersion === 'mt') {
        verseCompareTitle = 'MT';
        verseCompareContent = verse.verse_mt;

        if (verse.description_mt) {
            verseCompareDescription = verse.description_mt;
        }
    }
    else if (activeVerseVersion === 'tr') {
        verseCompareTitle = 'TR';
        verseCompareContent = verse.verse_tr;

        if (verse.description_tr) {
            verseCompareDescription = verse.description_tr;
        }
    }

    let highlighted_style = false;

    if (optiotalHighlight === undefined) {

        if (highlight) {
            highlighted_style = true;
        }

    }
    else {

        if (optiotalHighlight) {
            highlighted_style = true;
        }

    }

    const linkWrapper = (verseCompareDescription) => {
        const options = {
            replace: ({ name, attribs, children }) => {
                if (name === 'a' && attribs && attribs.href && attribs.href.includes('kjvcompare.com')) {
                    return <LinkWrapper to={attribs.href}>{domToReact(children)}</LinkWrapper>;
                }
            }
        };
    
        return parse(verseCompareDescription, options);
    };

    let jkb_verse = verse.verse_kjb;

    return (
        <>
            <Helmet>
                <title>KJV Compare - {verse.title}</title>
                <meta name="description" content={verseCompareDescription} />
                <meta property="og:title" content={verse.title} />
                <meta property="og:description" content={verseCompareDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://kjvcompare.com/${verse.slug}`} />
            </Helmet>
            <div className={`${styles.wrapper} ${highlighted_style ? styles.highlighted : ''} ${present_mode && styles[fade]}`}>
                <div className={styles.row}>
                    
                    <h1>{verse.title}</h1>

                    <div className={styles.verses}>

                        <div className={styles.col}>
                            <h4>KJB</h4>
                            <p style={{fontSize: fontSizeOrigin + 'px'}} dangerouslySetInnerHTML={{ __html: escapeEmoticons(jkb_verse) }}></p>
                        </div>

                        <div className={styles.col}>
                            <h4>{verseCompareTitle}</h4>
                            <p style={{fontSize: fontSizeAlternative + 'px'}} dangerouslySetInnerHTML={{ __html: escapeEmoticons(verseCompareContent) }}></p>
                        </div>

                    </div>

                    <div className={styles.versions_mob}>
                        <Version />
                    </div>

                    {(verseCompareDescription && notes) && (
                        <div className={styles.description}>
                            {linkWrapper(verseCompareDescription)}
                        </div>
                    )}

                    {verse.lost_cross_list.length > 0 && (
                        <div
                            className={styles.lost_cross_mob_button}
                            onClick={() => dispatch(setIsCrossPopupOpen(true))}
                        >
                            Lost Cross Ref. ({verse.lost_cross_list.length})
                        </div>
                    )}

                    {verse.categories && (
                        <div className={styles.categories}>
                            <strong>Categories:</strong>
                            {verse.categories.map(category => (
                                <span 
                                    key={category.id}
                                    onClick={() => handleCategoryChange(category._id)}
                                >
                                    {category.title}
                                </span>
                            ))}
                        </div>
                    )}

                </div>
            </div>
        </>
    );
}

function escapeEmoticons(str) {
    return str.replaceAll('🙂', ':)');
}

export default Verse;