import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import TabHead from '../../ui/other/TabHead';
import VerseForm from './VerseForm';
import ScrollableWrapper from '../ScrollableWrapper';
import CanonList from '../../common/CanonList/CanonList';
import CategoryList from '../../common/CategoryList/CategoryList';

import styles from './Menu.module.css';

function Menu({ handleClose, ...props }) {
    const viewMode = useSelector((state) => state.settings.viewMode);
    const isMobile = useSelector((state) => state.settings.isMobile);

    const [activeTab, setActiveTab] = useState(isMobile ? null : 2);
    const [activeViewMode, setActiveViewMode] = useState(viewMode);

    useEffect(() => {
        setActiveViewMode(viewMode);
    }, [viewMode]);

    const handleTabChange = (tab) => {

        if (isMobile && tab === activeTab) {
            setActiveTab(null);
        }
        else {
            setActiveTab(tab);
        }

    }

    const handleChangeViewMode = (mode) => {
        setActiveViewMode(mode);
    }

    return (
        <>
            <div className={`${styles.menu} ${props?.isOpen ? styles.active : ''} ${isMobile ? styles.mobile : ''}`}>
                <div className={styles.trigger} onClick={handleClose}>
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
                <nav className={styles.menuSidebar}>
                    <ul>
                        <li className={activeTab === 0 ? styles.active : ''} onClick={() => handleTabChange(0)}>
                            <span>SUBMIT</span>
                        </li>
                        <li className={activeTab === 1 ? styles.active : ''} onClick={() => handleTabChange(1)}>
                            <span>COPYRIGHT</span>
                        </li>
                        <li className={activeTab === 2 ? styles.active : ''} onClick={() => handleTabChange(2)}>
                            <span>NAVIGATE</span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="https://linktr.ee/truthischrist" target="_blank" rel="noreferrer">@TRUTHISCHRIST</a>
                        </li>
                    </ul>
                </nav>

                <div className={`${styles.tab} ${activeTab === 2 ? styles.active : ''}`}>
                    <div 
                        className={styles.tab_mobile_head} 
                        onClick={() => handleTabChange(2)}>
                            Navigate
                            <span>{activeTab === 2 ? '-' : '+'}</span>
                    </div>
                    <ScrollableWrapper className={`${styles.verses} ${styles.content}`}>

                        <div className={styles.verses_head}>
                            <TabHead 
                                className={`${styles.tab_head} ${activeViewMode === 'canon' ? styles.active : '' }`}
                                onClick={() => handleChangeViewMode('canon')}
                            >
                                CANON
                            </TabHead>
                            <TabHead 
                                className={`${styles.tab_head} ${activeViewMode === 'category' ? styles.active : '' }`}
                                onClick={() => handleChangeViewMode('category')}
                            >
                                CATEGORY
                            </TabHead>
                        </div>

                        <div className={styles.verses_body}>

                            {activeViewMode === 'canon' ? (
                                <CanonList onChange={handleClose} />
                            ) : (
                                <CategoryList onChange={handleClose} />
                            )}

                        </div>
                    </ScrollableWrapper>
                </div>
                <div className={`${styles.tab} ${activeTab === 1 ? styles.active : ''}`}>
                    <div 
                        className={styles.tab_mobile_head} 
                        onClick={() => handleTabChange(1)}>
                            Copyright
                            <span>{activeTab === 1 ? '-' : '+'}</span>
                    </div>
                    <ScrollableWrapper className={`${styles.copyright} ${styles.content}`}>
                        <TabHead className={styles.tab_head}>COPYRIGHT</TabHead>
                        <div>
                            <p>
                                KJV - King James Version
                                <br />
                                1769 Database - PUBLIC DOMAIN
                            </p>
                            <br />
                            <p>
                                NIV - New International Version
                                <br />
                                © 2011 by Biblica, Inc
                            </p>
                            <br />
                            <p>
                                ESV - English Standard Version
                                <br />
                                © 2016 by Crossway Bibles
                            </p>
                            <br />
                            <p>
                                NKJV - New King James Version
                                <br />
                                © 1982 by Thomas Nelson
                            </p>
                            <br />
                            <p>
                                NASB - New American Standard Bible
                                <br />
                                © 2020 by The Lockman Foundation
                            </p>
                            <br />
                            <p>
                                LSB - Legacy Standard Bible
                                <br />
                                © 2021 by The Lockman Foundation. All rights reserved. Managed in partnership with Three Sixteen Publishing Inc.
                            </p>
                            <br />
                            <p>
                                AMP - Amplified Version
                                <br />
                                © 2015 by The Lockman Foundation
                            </p>
                            <br />
                            <p>
                                NLT - New Living Translation
                                <br />
                                © 2015 by Tyndale House Foundation
                            </p>
                            <br />
                            <p>
                                NRSV - New Revised Standard Version
                                <br />
                                © 1989 by the Division of Christian Education of the National Council of the Churches of Christ in the United States of America
                                </p>
                            <br />
                            <p>
                                RSV - Revised Standard Version
                                <br />
                                © 1971 by the Division of Christian Education of the National Council of the Churches of Christ in the United States of America
                            </p>
                            <br />
                            <p>
                                ISV - International Standard Versoin
                                <br />
                                © 1995-2014 by ISV Foundation
                            </p>
                            <br />
                            <p>
                                HCSB - Holmon Christian Standard Bible
                                <br />
                                © 2009 by Holman Bible Publishers
                            </p>
                            <br />
                            <p>
                                MEV - Modern English Version
                                <br />
                                © 2014 by Military Bible Association
                            </p>
                            <br />
                            <p>TLB © 1971. by Tyndale House Foundation</p>
                        </div>
                    </ScrollableWrapper>
                </div>
                <div className={`${styles.tab} ${activeTab === 0 ? styles.active : ''}`}>
                    <div 
                        className={styles.tab_mobile_head} 
                        onClick={() => handleTabChange(0)}>
                            Submit
                            <span>{activeTab === 0 ? '-' : '+'}</span>
                    </div>
                    <ScrollableWrapper className={`${styles.verse_form} ${styles.content}`}>
                        <TabHead className={styles.tab_head}>SUBMIT A VERSE</TabHead>
                        <VerseForm />
                    </ScrollableWrapper>
                </div>
                {isMobile && <a className={styles.external_link} href="https://linktr.ee/truthischrist" target="_blank" rel="noreferrer">@TRUTHISCHRIST</a>}

            </div>
            <div className={`${styles.overlay} ${props?.isOpen ? styles.active : ''}`} onClick={handleClose}></div>
        </>
    );
}
    
export default Menu;