import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Field from '../../ui/form/Field/Field';
import TextField from '../../ui/form/TextField/TextField';
import ButtonPurple from '../../ui/buttons/ButtonPurple/ButtonPurple';

import { submitVerse } from '../../../services/api/contactService';

import styles from './VerseForm.module.css';

function VerseForm(props) {
    const [formLoading, setFormLoading] = useState(false);
    const [formSuccess, setSuccess] = useState(false);
    const [formError, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            reference: '',
            versions: '',
            message: ''
        },
        validationSchema: Yup.object({
            reference: Yup.string().required('Required'),
            versions: Yup.string().required('Required'),
            message: Yup.string().required('Required')
        }),
        onSubmit: async (values) => {

            if (formLoading) {
                return;
            }

            setFormLoading(true);
            setSuccess(false);
            setError('');

            try {
                await submitVerse(values);
                formik.resetForm();
                setSuccess(true);
            } catch (error) {
                // handleApiError(error);
                setError(error.response?.data.message || 'An error occurred, please try again later.');
            } finally {
                setFormLoading(false);
            }

        },
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit} {...props}>
            <h2>What did we miss?</h2>
            <p>Fill out the form below.</p>
            <Field 
                name="reference" 
                type="text" 
                placeholder="E.g.  1 John 5:7"
                label={<p>VERSE REFERENCE</p>}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference}
                error={formik.touched.reference && formik.errors.reference}
            />
            <Field 
                name="versions" 
                type="text" 
                placeholder="E.g.  NASB, NKJV, NIV"
                label={<p>WHICH VERSIONS ARE AFFECTED?</p>}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.versions}
                error={formik.touched.versions && formik.errors.versions}
            />
            <TextField 
                name="message"
                placeholder="E.g.  “Most of verse missing”"
                label={<p>DESCRIBE THE CHANGE <span>(AND OPTIONALLY ITS SIGNIFICANCE)</span></p>}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                error={formik.touched.message && formik.errors.message}
            />
            <ButtonPurple type="submit" disabled={formLoading}>
                {formLoading ? 'Submitting...' : 'Submit'}
            </ButtonPurple>

            {formSuccess && <p className={styles.success}>Thank you for your submission!</p>}
            {formError && <p className={styles.error}>{formError}</p>}

            <p>All submissions are anonymous.</p>
        </form>
    );
}
    
export default VerseForm;