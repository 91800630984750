import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getRandomVerse } from '../services/api/verseService';

import Header from '../components/layout/Header';
import Controlls from '../components/layout/Controlls/Controlls';
import Verse from '../components/layout/Verse/Verse';
import BackgroundMusic from '../components/other/BackgroundMusic';

import styles from './Main.module.css';

import video from '../assets/video.mp4';

function Main() {
    const navigate = useNavigate();

    const particles = useSelector(state => state.settings.settings.particles);
    const present_mode = useSelector(state => state.settings.settings.present_mode);

    const [hideElements, setHideElements] = useState(present_mode);
    const [mouseMoved, setMouseMoved] = useState(false);

    useEffect(() => {
        
        // if url is / then redirect to /home
        if (window.location.pathname === '/') {
            getRandomVerse().then(response => {
                if (response.slug) {
                    navigate(`/${response.slug}`);
                }
                // console.log(response);
            }).catch(error => {
                console.error(error);
            });
        }

    }, [navigate]);

    useEffect(() => {
        let hideTimeout;

        if (present_mode) {
            if (!hideElements) {
                setHideElements(true);
            } else {
                const handleMouseMove = () => {
                    clearTimeout(hideTimeout);
                    setMouseMoved(true);
                    hideTimeout = setTimeout(() => {
                        setHideElements(true);
                        setMouseMoved(false);
                    }, 3000);
                };

                document.addEventListener('mousemove', handleMouseMove);

                return () => {
                    clearTimeout(hideTimeout);
                    document.removeEventListener('mousemove', handleMouseMove);
                };
            }
        } else {
            setHideElements(false);
        }
    }, [present_mode, hideElements, mouseMoved]);

    const handleInstantHide = (e) => {
        if (e) {
            setMouseMoved(false);
        }
        else {
            setMouseMoved(true);
        }
    }

    return (
        <>
            <Header className={`${(hideElements && !mouseMoved) ? 'hidden' : ''}`} />
            <Verse />
            <Controlls 
                className={`${(hideElements && !mouseMoved) ? 'hidden' : ''}`}
                instantHide={handleInstantHide}
            />
            <video 
                src={video} 
                playsInline 
                loop 
                muted 
                autoPlay 
                className={`${styles.video} ${particles ? styles.active : ''}`}
            />

            <BackgroundMusic />
        </>
    );
}
    
export default Main;