import LinkWrapper from '../../wrappers/LinkWrapper';

import styles from './ButtonPurple.module.css';

function ButtonPurple({ children, props }) {

    if (props?.href) {
        return (
            <a 
                className={styles.button} 
                {...props} 
            >
                <span>{children}</span>
            </a>
        );
    }
    else if (props?.to) {
        return (
            <LinkWrapper 
                className={styles.button} 
                {...props} 
            >
                <span>{children}</span>
            </LinkWrapper>
        );
    }
    return (
        <button 
            className={styles.button} 
            {...props} 
        >
            <span>{children}</span>
        </button>
    );
}
    
export default ButtonPurple;