import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRepeat } from '../../app/features/settingsSlice';

import track_1 from '../../assets/sounds/Track 1.mp3';
import track_2 from '../../assets/sounds/Track 2.mp3';
import track_3 from '../../assets/sounds/Track 3.mp3';
import track_4 from '../../assets/sounds/Track 4.mp3';
import track_5 from '../../assets/sounds/Track 5.mp3';
import track_6 from '../../assets/sounds/Track 6.mp3';
import track_7 from '../../assets/sounds/Track 7.mp3';
import track_8 from '../../assets/sounds/Track 8.mp3';
import track_9 from '../../assets/sounds/Track 9.mp3';
import track_10 from '../../assets/sounds/Track 10.mp3';
import track_11 from '../../assets/sounds/Track 11.mp3';
import track_12 from '../../assets/sounds/Track 12.mp3';
import track_13 from '../../assets/sounds/Track 13.mp3';
import track_14 from '../../assets/sounds/Track 14.mp3';
import track_15 from '../../assets/sounds/Track 15.mp3';
import track_16 from '../../assets/sounds/Track 16.mp3';
import track_17 from '../../assets/sounds/Track 17.mp3';

const BackgroundMusic = () => {
    const [userInteracted, setUserInteracted] = useState(false);
    const [volume, setVolume] = useState(0);
    const isUserSearching = useSelector(state => state.settings.isUserSearching);
    const musicEnabled = useSelector(state => state.settings.settings.music);
    const repeat = useSelector(state => state.settings.settings.repeat);
    const audioRef = useRef(null);
    const trackIndex = useRef(0);
    const tracks = useMemo(() => [
        track_1,
        track_2,
        track_3,
        track_4,
        track_5,
        track_6,
        track_7,
        track_8,
        track_9,
        track_10,
        track_11,
        track_12,
        track_13,
        track_14,
        track_15,
        track_16,
        track_17,
    ], []);

    const dispatch = useDispatch();

    const handleTrackEnd = useCallback(() => {

        if (!repeat) {
            trackIndex.current = (trackIndex.current + 1) % tracks.length;
        }

        audioRef.current.src = tracks[trackIndex.current];
        audioRef.current.play();
    }, [audioRef, tracks, trackIndex, repeat]);

    const playTrack = useCallback(() => {
        if (!audioRef.current) {
            audioRef.current = new Audio(tracks[trackIndex.current]);
            audioRef.current.addEventListener('ended', handleTrackEnd);
        }
        audioRef.current.play();
    }, [audioRef, tracks, trackIndex, handleTrackEnd]);

    const pauseTrack = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
    }, [audioRef]);

    useEffect(() => {
        if (musicEnabled) {
            playTrack();
        } else {
            pauseTrack();
        }

        return () => {
            pauseTrack();
        };
    }, [musicEnabled, userInteracted, playTrack, pauseTrack]);

    useEffect(() => {
        const handleWindowClick = () => {
            setUserInteracted(true);
        };

        if (!userInteracted) {
            window.addEventListener('click', handleWindowClick);
        }

        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, [userInteracted]);

    useEffect(() => {

        const handleKeydown = (event) => {
            const key = event.keyCode;
            const isModifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;

            // Ignore common shortcuts like Cmd+C, Cmd+V, etc.
            if (isModifierKey) {
                return;
            }

            // R
            if (key === 82) {
                event.preventDefault();
                dispatch(setRepeat(!repeat));
            }
            
            // >
            if (key === 190) {
                event.preventDefault();
                trackIndex.current = (trackIndex.current + 1) % tracks.length;
                audioRef.current.src = tracks[trackIndex.current];
                audioRef.current.play();
            }

            // <
            if (key === 188) {
                event.preventDefault();

                if (trackIndex.current === 0) {
                    trackIndex.current = tracks.length - 1;
                }
                else {
                    trackIndex.current = (trackIndex.current - 1) % tracks.length;
                }

                audioRef.current.src = tracks[trackIndex.current];
                audioRef.current.play();
            }
            
            // 0
            if (key === 48) {
                event.preventDefault();

                if (audioRef.current.volume === 0) {
                    audioRef.current.volume = volume;
                }
                else {
                    setVolume(audioRef.current.volume);
                    audioRef.current.volume = 0;
                }

            }

            // 1
            if (key === 49) {
                event.preventDefault();
                audioRef.current.volume = 0.1;
            }

            // 2
            if (key === 50) {
                event.preventDefault();
                audioRef.current.volume = 0.2;
            }

            // 3
            if (key === 51) {
                event.preventDefault();
                audioRef.current.volume = 0.3;
            }

            // 4
            if (key === 52) {
                event.preventDefault();
                audioRef.current.volume = 0.4;
            }

            // 5
            if (key === 53) {
                event.preventDefault();
                audioRef.current.volume = 0.5;
            }

            // 6
            if (key === 54) {
                event.preventDefault();
                audioRef.current.volume = 0.6;
            }

            // 7
            if (key === 55) {
                event.preventDefault();
                audioRef.current.volume = 0.7;
            }

            // 8
            if (key === 56) {
                event.preventDefault();
                audioRef.current.volume = 0.8;
            }

            // 9
            if (key === 57) {
                event.preventDefault();
                audioRef.current.volume = 0.9;
            }

        };

        if (!isUserSearching) {
            window.addEventListener('keydown', handleKeydown);
        }
    
        return () => {
          window.removeEventListener('keydown', handleKeydown);
        };
    }, [isUserSearching, tracks, repeat, volume, dispatch]);

    return null;
};

export default BackgroundMusic;
